import _class_call_check from "@swc/helpers/src/_class_call_check.mjs";
import JSBI from "jsbi";
import { SolidityType } from "../constants";
import { validateSolidityTypeInstance } from "../utils";
/**
 * A currency is any fungible financial instrument on Ethereum, including Ether and all ERC20 tokens.
 *
 * The only instance of the base class `Currency` is Ether.
 */ export var Currency = function Currency(decimals, symbol, name) {
    "use strict";
    _class_call_check(this, Currency);
    validateSolidityTypeInstance(JSBI.BigInt(decimals), SolidityType.uint8);
    this.decimals = decimals;
    this.symbol = symbol;
    this.name = name;
};
/**
   * The only instance of the base class `Currency`.
   */ Currency.ETHER = new Currency(18, "ETH", "ETH");
var ETHER = Currency.ETHER;
export { ETHER };
