import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId[// ETHEREUM = 1,
    "SEPOLIA"] = 11155111] = "SEPOLIA";
    ChainId[ChainId[// BSC = 56,
    "OP_MAINNET"] = 10] = "OP_MAINNET";
    ChainId[ChainId["OP_SEPOLIA"] = 11155420] = "OP_SEPOLIA";
    ChainId[ChainId["POLYGON"] = 137] = "POLYGON";
    ChainId[ChainId["ARBITRUM_ONE"] = 42161] = "ARBITRUM_ONE";
    ChainId[ChainId["HEIF_LAYER"] = 12122] = "HEIF_LAYER";
    ChainId[ChainId["BASE"] = 8453] = "BASE";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS = "0xb1697b36346d1800aC29d0fB886883e856803555";
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.OP_MAINNET, FACTORY_ADDRESS), _define_property(_obj, ChainId.OP_SEPOLIA, "0x2B64dE417aFbDC97acbD71773eB719C58F7B430B"), _define_property(_obj, ChainId.SEPOLIA, "0x297f53Ac0BbCC6a1Cfa1D9ad026FD2A1747443FE"), _define_property(_obj, ChainId.HEIF_LAYER, "0xDd7D4e332FD047682bB427722F00C4bA6d5e2188"), _define_property(_obj, ChainId.POLYGON, FACTORY_ADDRESS), _define_property(_obj, ChainId.ARBITRUM_ONE, "0xb41025eA8EF558dCe79e0f0A6ac8df1640D828D4"), _define_property(_obj, ChainId.BASE, "0xd6908cCe132490512a2AD0795C18460dDD46f538"), _obj);
export var INIT_CODE_HASH = "0xaa68502851d9874dd6e285c590b4be8d6779ced7e0f940f16648952565840b28";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.OP_MAINNET, INIT_CODE_HASH), _define_property(_obj1, ChainId.POLYGON, INIT_CODE_HASH), _define_property(_obj1, ChainId.ARBITRUM_ONE, INIT_CODE_HASH), _define_property(_obj1, ChainId.HEIF_LAYER, "0xaa68502851d9874dd6e285c590b4be8d6779ced7e0f940f16648952565840b28"), _define_property(_obj1, ChainId.BASE, "0xaa68502851d9874dd6e285c590b4be8d6779ced7e0f940f16648952565840b28"), _define_property(_obj1, ChainId.OP_SEPOLIA, "0xfa3edc199bacd1c2f836849ddf47a92f94bbe948d1320813ccd08001d80c6863"), _define_property(_obj1, ChainId.SEPOLIA, "0xaa68502851d9874dd6e285c590b4be8d6779ced7e0f940f16648952565840b28"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var FEES_NUMERATOR = JSBI.BigInt(9975);
export var FEES_DENOMINATOR = JSBI.BigInt(10000);
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
