import {Box, Text, UserMenu, UserMenuDivider, UserMenuItem} from '@pancakeswap/uikit'
import {arbitrumOne, baseMainnet, optimismMainnet, optimismSepolia, sepolia} from '@pancakeswap/wagmi'
import {useTranslation} from '@pancakeswap/localization'
import Image from 'next/image'
import {setupNetwork} from 'utils/wallet'
import {UnsupportedChainIdError, useWeb3React} from '@web3-react/core'
import {optimism, polygon} from "wagmi/chains";
import Warning from '../views/TradingCompetition/pngs/sign.png'

const chains = [
    optimismMainnet,
    optimismSepolia,
    // polygon,
    arbitrumOne,
    sepolia,
    baseMainnet,
]

export const NetworkSelect = () => {
    const {t} = useTranslation()
    return (
        <>
            <Box px="16px" py="8px">
                <Text>{t('Select a Network')}</Text>
            </Box>
            <UserMenuDivider/>
            {chains.map((chain) => (
                <UserMenuItem key={chain.id} style={{justifyContent: 'flex-start'}}
                              onClick={() => setupNetwork(chain.id)}>
                    <Image width={24} height={24} src={`/images/chains/${chain.id}.png`} unoptimized/>
                    <Text pl="12px">{chain.name}</Text>
                </UserMenuItem>
            ))}
        </>
    )
}

export const NetworkSwitcher = () => {
    const {account, error, chainId} = useWeb3React()
    const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError
    if (account || isWrongNetwork) {
        if (chainId === optimismSepolia.id) {
            return (
                <UserMenu
                    mr="8px"
                    avatarSrc="https://cdn.pancakeswap.com/chains/10.png"
                    account={optimismSepolia.name}
                    ellipsis={false}
                >
                    {() => <NetworkSelect/>}
                </UserMenu>
            )
        }

        if (chainId === optimism.id) {
            return (
                <UserMenu
                    mr="8px"
                    avatarSrc="https://cdn.pancakeswap.com/chains/10.png"
                    account={optimism.name}
                    ellipsis={false}
                >
                    {() => <NetworkSelect/>}
                </UserMenu>
            )
        }

        if (chainId === polygon.id) {
            return (
                <UserMenu
                    mr="8px"
                    avatarSrc="https://cdn.pancakeswap.com/chains/137.png"
                    account={polygon.name}
                    ellipsis={false}
                >
                    {() => <NetworkSelect/>}
                </UserMenu>
            )
        }

        if (chainId === arbitrumOne.id) {
            return (
                <UserMenu
                    mr="8px"
                    avatarSrc="https://cdn.pancakeswap.com/chains/42161.png"
                    account={arbitrumOne.name}
                    ellipsis={false}
                >
                    {() => <NetworkSelect/>}
                </UserMenu>
            )
        }

        if (chainId === baseMainnet.id) {
            return (
                <UserMenu
                    mr="8px"
                    avatarSrc="/images/chains/8453.png"
                    account={baseMainnet.name}
                    ellipsis={false}
                >
                    {() => <NetworkSelect/>}
                </UserMenu>
            )
        }

        if (chainId === sepolia.id) {
            return (
                <UserMenu
                    mr="8px"
                    avatarSrc="https://cdn.pancakeswap.com/chains/1.png"
                    account={sepolia.name}
                    ellipsis={false}
                >
                    {() => <NetworkSelect/>}
                </UserMenu>
            )
        }

        return (
            <UserMenu
                mr="8px"
                avatarSrc={Warning.src}
                account="Select available network"
                ellipsis={false}
            >
                {() => <NetworkSelect/>}
            </UserMenu>
        )
    }
    return null;
}
