import {
  arbitrumRinkeby,
  optimismKovan,
  polygonMumbai,
  rinkeby,
  mainnet,
  arbitrum,
  optimism,
  polygon,
} from 'wagmi/chains'
import { Chain } from 'wagmi'

export const avalandche: Chain = {
  id: 43114,
  name: 'Avalanche C-Chain',
  network: 'avalanche',
  rpcUrls: {
    default: 'https://rpc.ankr.com/avalanche',
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://snowtrace.io/',
    },
  },
}

export const avalandcheFuji: Chain = {
  id: 43113,
  name: 'Avalanche Fuji',
  network: 'avalanche-fuji',
  rpcUrls: {
    default: 'https://rpc.ankr.com/avalanche_fuji',
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://testnet.snowtrace.io/',
    },
  },
  testnet: true,
}

export const fantomOpera: Chain = {
  id: 250,
  name: 'Fantom Opera',
  network: 'fantom',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    default: 'https://rpc.ftm.tools',
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://ftmscan.com',
    },
  },
}

export const fantomTestnet: Chain = {
  id: 4002,
  name: 'Fantom Testnet',
  network: 'fantom-testnet',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    default: 'https://rpc.testnet.fantom.network',
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://testnet.ftmscan.com',
    },
  },
  testnet: true,
}

export const arbitrumOne: Chain = {
  id: 42161,
  name: 'Abitrum One',
  network: 'Abitrum One',
  rpcUrls: {
    default: 'https://arb1.arbitrum.io/rpc',
    public: 'https://arb1.arbitrum.io/rpc',
  },
  blockExplorers: {
    default: {
      name: 'Arbiscan',
      url: 'https://arbiscan.io/'
    },
    etherscan: {
      name: 'Arbiscan',
      url: 'https://arbiscan.io/'
    },
  },
  multicall: {
    address: '0xEe8d287B844959ADe40d718Dc23077ba920e2f07', // todo
    blockCreated: 1463669,
  },
  nativeCurrency: {
    name: 'WETH',
    symbol: 'weth',
    decimals: 18,
  },
}

export const optimismMainnet: Chain = {
  id: 10,
  name: 'Optimism Mainnet',
  network: 'Optimism',
  rpcUrls: {
    default: 'https://optimism-rpc.publicnode.com',
    public: 'https://optimism-rpc.publicnode.com',
  },
  blockExplorers: {
    default: {
      name: 'Optimistic Scan',
      url: 'https://optimistic.etherscan.io'
    },
    etherscan: {
      name: 'Optimistic Scan',
      url: 'https://optimistic.etherscan.io'
    },
  },
  multicall: {
    address: '0xEe8d287B844959ADe40d718Dc23077ba920e2f07',
    blockCreated: 1463669,
  },
  nativeCurrency: {
    name: 'WETH',
    symbol: 'weth',
    decimals: 18,
  },
}

export const sepolia: Chain = {
  id: 11155111,
  name: 'Sepolia',
  network: 'sepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: 'https://rpc.sepolia.io',
    public: 'https://rpc.sepolia.io',
  },
  blockExplorers: {
    default: {
      name: 'Sepolia',
      url: 'https://sepolia.etherscan.io/' },
  },
  multicall: {
    address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    blockCreated: 9759845,
  },
  testnet: true,
}

export const optimismSepolia: Chain = {
  id: 11155420,
  name: 'Optimism Sepolia',
  network: 'op-sepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: 'https://sepolia.optimism.io',
    public: 'https://sepolia.optimism.io',
  },
  blockExplorers: {
    default: { name: 'OpSepolia', url: 'https://optimism-sepolia.blockscout.com/' },
  },
  multicall: {
    address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    blockCreated: 9759845,
  },
  testnet: true,
}

export const heifLayer: Chain = {
  id: 12122,
  name: 'Heif Layer',
  network: 'heif-layer',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: 'https://rpc.heifereum.com',
    public: 'https://rpc.heifereum.com',
  },
  blockExplorers: {
    default: { name: 'HeifLayer', url: 'https://explorer.heifereum.com/' },
  },
  multicall: {
    address: '0x4aF20BF0a254e7641B12e290ddC6e5eE8B09e359',
    blockCreated: 9759845,
  },
  testnet: true,
}

export const baseMainnet: Chain = {
  id: 8453,
  name: 'Base',
  network: 'base',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: 'https://mainnet.base.org',
    public: 'https://mainnet.base.org',
  },
  blockExplorers: {
    default: { name: 'Basescan', url: 'https://basescan.org/' },
  },
  multicall: {
    address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    blockCreated: 9759845,
  },
}

export const CHAINS_TESTNET = [
  optimismSepolia,
  sepolia,
  rinkeby,
  arbitrumRinkeby,
  optimismKovan,
  polygonMumbai,
  avalandcheFuji,
  fantomTestnet,
]

export const CHAINS_STARGATE_TESTNET = [
  rinkeby,
  arbitrumRinkeby,
  optimismKovan,
  polygonMumbai,
  avalandcheFuji,
  fantomTestnet,
]

export const CHAINS_STARGATE = [mainnet, arbitrum, optimism, polygon, avalandche, fantomOpera]

export const CHAINS = [optimismMainnet, mainnet, arbitrum, optimism, polygon, fantomOpera, avalandche]
