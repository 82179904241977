export const OP_MAINNET_URLS = [
  'https://bsc-dataseed1.ninicoin.io',
  'https://bsc-dataseed1.defibit.io',
  'https://bsc-dataseed.binance.org',
]

export const OP_SEPOLIA_TESTNET_RPC_URLS = [
  'https://data-seed-prebsc-1-s1.binance.org:8545',
  'https://data-seed-prebsc-2-s1.binance.org:8545',
  'https://data-seed-prebsc-1-s3.binance.org:8545',
]

export const SEPOLIA_TESTNET_RPC_URLS = [
  'https://rpc.sepolia.org',
  'https://rpc.sepolia.org',
  'https://rpc.sepolia.org',
]

export const HEIF_LAYER_RPC_URLS = [
  'https://rpc.heifereum.com',
  'https://rpc.heifereum.com',
  'https://rpc.heifereum.com',
]

export const POLYGON_RPC_URLS = [
  'https://polygon-rpc.com',
  'https://polygon-rpc.com',
  'https://polygon-rpc.com',
]

export const ARBITRUM_ONE_RPC_URLS = [
  'https://arb1.arbitrum.io/rpc',
  'https://arb1.arbitrum.io/rpc',
  'https://arb1.arbitrum.io/rpc',
]

export const BASE_RPC_URLS = [
  'https://mainnet.base.org',
  'https://mainnet.base.org',
  'https://mainnet.base.org',
]