// used to construct the list of all pairs we consider by default in the frontend
import { ChainId, Token } from '@pancakeswap/sdk'
import {baseMainnetTokens, opMainnetTokens, opSepoliaTokens, polygonMainnetTokens, sepoliaTokens} from './tokens'
import { ChainTokenList } from './types'

export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.OP_MAINNET]: [opMainnetTokens.weth, opMainnetTokens.dai, opMainnetTokens.busd, opMainnetTokens.usdt],
  [ChainId.POLYGON]: [polygonMainnetTokens.weth, polygonMainnetTokens.ejoe, polygonMainnetTokens.usdc, polygonMainnetTokens.usdt],
  [ChainId.ARBITRUM_ONE]: [opMainnetTokens.weth, opMainnetTokens.dai, opMainnetTokens.busd, opMainnetTokens.usdt],
  [ChainId.OP_SEPOLIA]: [opSepoliaTokens.weth, opSepoliaTokens.ejoe, opSepoliaTokens.usdt, opSepoliaTokens.usdc],
  [ChainId.SEPOLIA]: [sepoliaTokens.weth, sepoliaTokens.ejoe, sepoliaTokens.usdt, sepoliaTokens.usdc],
  [ChainId.BASE]: [baseMainnetTokens.weth, baseMainnetTokens.ejoe, baseMainnetTokens.usdt, baseMainnetTokens.usdc],
  [ChainId.HEIF_LAYER]: [sepoliaTokens.weth, sepoliaTokens.ejoe, sepoliaTokens.usdt, sepoliaTokens.usdc],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.OP_MAINNET]: [
    [opMainnetTokens.ejoe, opMainnetTokens.weth],
    [opMainnetTokens.busd, opMainnetTokens.usdt],
    [opMainnetTokens.dai, opMainnetTokens.usdt],
  ],
}

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C',
]

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export { default as farmsConfig } from './farms'
export { default as ifosConfig } from './ifo'

export const FAST_INTERVAL = 10000
export const SLOW_INTERVAL = 60000

export const NOT_ON_SALE_SELLER = '0x0000000000000000000000000000000000000000'

export const FARM_AUCTION_HOSTING_IN_SECONDS = 604800

export const PREDICTION_TOOLTIP_DISMISS_KEY = 'prediction-switcher-dismiss-tooltip'

// Gelato uses this address to define a native currency in all chains
export const GELATO_NATIVE = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

export const EXCHANGE_DOCS_URLS = 'https://docs.pancakeswap.finance/products/pancakeswap-exchange'
